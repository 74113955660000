import { useQuery } from '@tanstack/react-query';
import ApiClient from 'redux/helpers/ApiClient';
import { LoadAuthResponse } from 'types/actions/auth';

const apiClient = new ApiClient();

export const useGetUser = () =>
  useQuery({
    queryFn: async (): Promise<LoadAuthResponse> =>
      apiClient.get('/mkt/users/me'),
    queryKey: ['user'],
    select: data => (Object.keys(data).length === 0 ? null : data)
  });
