import {
  Alert,
  Button,
  DocumentIframe,
  FileIcon,
  FileUploader,
  RadioButton,
  Select
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkDocumentSignStatus,
  generateW9Form,
  loadInvestmentAccount,
  offlineW9Upload,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  AnyInvestmentAccountType,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { setFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as sharedStyles from '../AccountShared.module.scss';
import { taxFilingStatuses } from '../contents';
import { isComplete } from '../helpers';
import { w9Schema } from '../validation';
import AccountWrap from './AccountWrap/AccountWrap';
import BackLink from './BackLink/BackLink';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<AnyInvestmentAccountType>;
  uploading: boolean;
}

interface FormFields {
  investment_account?: {
    tax_filing_status?: string;
  };
}

const W9DocumentUpdate = ({
  dispatch,
  investmentAccount,
  uploading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const [loadingW9, setLoadingW9] = useState(true);
  const [w9Signed, setW9Signed] = useState(false);
  const [w9DownloadUrl, setW9DownloadUrl] = useState(null);
  const [w9SignUrl, setW9SignUrl] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [signSelected, setSignSelected] = useState(false);
  const [uploadSelected, setUploadSelected] = useState(false);

  let defaultValues = {};
  if (investmentAccount?.type !== 'entity')
    defaultValues = {
      investment_account: {
        tax_filing_status: investmentAccount?.tax_filing_status
      }
    };

  const isEntity = investmentAccount?.account_type === 'entity';

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: !isEntity && yupResolver(w9Schema),
    values: defaultValues
  });

  const loadSignedW9 = () => {
    dispatch(checkDocumentSignStatus(params.reference_id, 'w9_form')).then(
      doc => {
        if (doc.status === 'Complete' && doc.download_url) {
          setW9Signed(true);
          setW9DownloadUrl(doc.download_url);
        }
      }
    );
  };

  useEffect(() => {
    document.title = 'My Accounts | Organizational Document';

    let checkDocumentInterval;

    dispatch(loadInvestmentAccount(params.reference_id))
      .then(res => {
        if (res?.investment_account) {
          loadSignedW9();
          setLoadingW9(false);
          checkDocumentInterval = setInterval(loadSignedW9, 5000);
        }
      })
      .catch(error => handleErrorResponse(navigate, error));

    return () => {
      clearInterval(checkDocumentInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpload = file => {
    setUploadSuccess(false);
    dispatch(
      offlineW9Upload({
        attachment: file,
        data: {
          account_id: params.reference_id,
          type: 'w9_form'
        }
      })
    ).then(res => {
      if (res.signing_url) setUploadSuccess(true);
    });
  };

  const onSubmitSuccess = () => {
    if (
      isComplete(investmentAccount) &&
      !location.pathname.includes('/accounts/wizard')
    ) {
      navigate(
        `/accounts/update/${investmentAccount.account_type}/documents/${params.reference_id}`
      );
    } else {
      EmAnalytics.track('Submits W9 Document', 'Onboarding', {
        account_type: utils.startCase(investmentAccount.account_type)
      });
      if (investmentAccount.type === 'joint account')
        navigate(
          `/accounts/wizard/${investmentAccount.account_type}/poa/${params.reference_id}`
        );
      else
        navigate(
          `/accounts/wizard/${investmentAccount.account_type}/link_bank/${params.reference_id}`
        );
    }
  };

  const onSubmit = values => {
    if (isEntity) {
      return onSubmitSuccess();
    }
    return dispatch(
      updateInvestmentAccount({
        investment_account: {
          id: investmentAccount.id,
          ...values.investment_account
        }
      })
    )
      .then(onSubmitSuccess)
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const handleTypeChange = type => {
    setW9Signed(false);
    setW9DownloadUrl(null);

    if (type === 'sign') {
      dispatch(
        generateW9Form(investmentAccount.user_id, investmentAccount.id)
      ).then(doc => {
        setW9SignUrl(doc.Investor);
      });

      setSignSelected(true);
      setUploadSelected(false);
    } else if (type === 'upload') {
      setSignSelected(false);
      setUploadSelected(true);
    }
  };

  let buttonDisabled = false;
  let completeAccount = false;

  if (investmentAccount) {
    completeAccount = investmentAccount && isComplete(investmentAccount);
    if (
      uploading ||
      isSubmitting ||
      (!completeAccount && !w9Signed && !uploadSuccess)
    )
      buttonDisabled = true;
  }

  return (
    <AccountWrap account={investmentAccount} loading={!investmentAccount}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {investmentAccount && (
          <>
            <h4 className="margin-top-0">
              We are required to collect a W-9 tax document for each Investment
              Account
            </h4>
            {!isEntity && (
              <>
                <h6>How do you file your taxes?</h6>
                <Controller
                  control={control}
                  name="investment_account.tax_filing_status"
                  render={({ field }) => (
                    <Select
                      {...setFieldProps(field, errors)}
                      className="input-fixed-width"
                      label="Tax Filing Status"
                      options={taxFilingStatuses.map(status => ({
                        label: status.label,
                        value: status.value
                      }))}
                    />
                  )}
                />
              </>
            )}

            <p className="margin-xx">
              To submit a W-9 form for this account, please sign electronically
              or upload a recent copy.
            </p>
            {w9Signed && w9DownloadUrl && (
              <div className="margin-xx">
                {loadingW9 ? (
                  <p>Loading W9...</p>
                ) : (
                  <>
                    <FileIcon
                      className={sharedStyles.docIcon}
                      url="agreement.pdf"
                    />
                    <a
                      className={sharedStyles.docLink}
                      href={w9DownloadUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current W9
                    </a>
                  </>
                )}
              </div>
            )}
            <div className="sign-upload-select">
              <h5>Submit W-9 form</h5>
              <div className="margin-xx radio-group">
                <RadioButton
                  checked={signSelected}
                  hideError
                  id="sign"
                  label="Sign Electronically"
                  name="w9-type"
                  onChange={() => {
                    handleTypeChange('sign');
                  }}
                />
                <RadioButton
                  checked={uploadSelected}
                  id="upload"
                  label="Upload"
                  name="w9-type"
                  onChange={() => {
                    handleTypeChange('upload');
                  }}
                />
              </div>
            </div>
            {uploadSelected && (
              <div>
                <div className="margin-xx">
                  <FileUploader
                    acceptedFileTypes={['PDF']}
                    maxSize={maxUploadSize}
                    onRemove={() => {}}
                    upload={handleUpload}
                    uploading={uploading}
                  />
                </div>
              </div>
            )}
            {signSelected && (
              <div>
                <div className="text-center">
                  {isSubmitting ? (
                    <p>Loading W9...</p>
                  ) : (
                    <DocumentIframe src={w9SignUrl} title="Sign W9" />
                  )}
                </div>
              </div>
            )}
            {w9DownloadUrl && signSelected && (
              <Alert type="positive">W9 successfully submitted.</Alert>
            )}

            <FormError errors={errors} />

            <div className="forwardBackButtonWrapCompact">
              <Button
                className="button-fixed-width"
                disabled={buttonDisabled}
                loading={isSubmitting}
                type="submit"
                variant="orange"
              >
                {completeAccount ? 'Update' : 'Continue'}
              </Button>
              <BackLink
                investmentAccount={investmentAccount}
                page="W9 Document"
              />
            </div>
          </>
        )}
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    investmentAccount: state.account.investmentAccount,
    uploading: state.account.uploading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(W9DocumentUpdate);
