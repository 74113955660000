// TODO: Remove the Sentry captureMessage and use commented reject with the error
import * as Sentry from '@sentry/react';

export const recaptchaPublicKey = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY;

export const loadRecaptchaScript = (): Promise<void> => {
  return new Promise(resolve => {
    if (document.getElementById('recaptchaScript')) return resolve();

    const script = document.createElement('script');
    script.id = 'recaptchaScript';
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaPublicKey}`;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () =>
      Sentry.captureMessage('Failed to load recaptcha script');

    document.body.appendChild(script);
  });
};

export const removeRecaptchaScript = () => {
  const recaptchaScript = document.getElementById('recaptchaScript');
  const recaptchaBadge = document.querySelector('.grecaptcha-badge');

  if (recaptchaScript) recaptchaScript.remove();
  if (recaptchaBadge) recaptchaBadge.remove();
};

const executeFunctionExists = () =>
  window.grecaptcha?.enterprise?.execute &&
  typeof window.grecaptcha?.enterprise?.execute === 'function';

const ensureRecaptchaReady = async (): Promise<void> => {
  if (executeFunctionExists()) return;

  await new Promise<void>(resolve => {
    let attempts = 0;
    const maxAttempts = 100; // Wait ~10 seconds

    const interval = setInterval(() => {
      if (executeFunctionExists()) {
        clearInterval(interval);
        return resolve();
      }

      if (++attempts >= maxAttempts) {
        clearInterval(interval);
        Sentry.captureMessage('reCAPTCHA failed to initialize');
        return resolve();
      }
    }, 100);
  });
};

export const getRecaptchaToken = async (action: string): Promise<string> => {
  await ensureRecaptchaReady();
  if (!executeFunctionExists()) return '';

  return new Promise(resolve => {
    window.grecaptcha.enterprise
      .execute(recaptchaPublicKey, { action })
      .then(token => resolve(token));
  });
};
