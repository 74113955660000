import './appSetup';

import '@equitymultiple/react-eui/dist/index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import createStore from './redux/create';
import AppRoutes from './routes';

const store = createStore();
const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider key="provider" store={store}>
    <QueryClientProvider client={queryClient}>
      <AppRoutes store={store} />
    </QueryClientProvider>
  </Provider>
);
