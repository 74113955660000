import superagent from 'superagent';
import {
  isAuthenticatedPath,
  updateSessionTimer,
  workerScript
} from 'utilities/sessionTimeout';

import humane from '../../utilities/humane';
import utils from '../../utilities/utils';

const methods = ['get', 'post', 'put', 'patch', 'del'];
const createOrUpdateMethods = ['post', 'put', 'patch'];
const passwordFields = ['password', 'current_password'];

const sessionTimerWorker = new Worker(workerScript);
sessionTimerWorker.onmessage = ({ data }) => {
  updateSessionTimer(data, window.location.pathname);
};

function handleRedirect(req) {
  req.on('response', res => {
    if (res.status === 308) {
      window.location.href = res.body.location;
    } else if (res.status === 401) {
      if (window.location.href.indexOf('/users/sign_in') < 0) {
        window.location.href = '/users/sign_in';
        humane.error('You need to sign in or sign up before continuing.');
        if (
          window.location.href.indexOf('/users/sign') < 0 &&
          window.location.href.indexOf('/users/password') < 0
        ) {
          utils.setLocalStorage('referer', window.location.href);
        }
      }
    }
  });
}

export interface ApiClientType {
  del: (...args: unknown[]) => Promise<Record<string, unknown>>;
  get<T>(url: string, options?: Record<string, unknown>): Promise<T>;
  patch: (...args: unknown[]) => Promise<Record<string, unknown>>;
  post: (...args: unknown[]) => Promise<Record<string, unknown>>;
  put: (...args: unknown[]) => Promise<Record<string, unknown>>;
}

class ApiClient implements ApiClientType {
  del: ApiClientType['del'];
  get!: ApiClientType['get'];
  patch!: ApiClientType['patch'];
  post!: ApiClientType['post'];
  put!: ApiClientType['put'];

  constructor(simulate = null) {
    methods.forEach(method => {
      this[method] = (
        path,
        options = {
          attach: null,
          data: null,
          excludeToken: false,
          field: null,
          header: null,
          isBlob: false,
          params: null
        }
      ) =>
        new Promise((resolve, reject) => {
          const { attach, data, excludeToken, field, header, isBlob, params } =
            options;

          const request = superagent[method](path);

          if (!simulate) {
            request.use(handleRedirect);

            if (!attach) {
              request.set('Accept', 'application/json');
              request.set('Content-Type', 'application/json');
            }

            if (isBlob) {
              request.responseType('blob');
            }

            if (header) request.set(header);

            request.withCredentials();

            if (!excludeToken) {
              const csrfToken = utils.getLocalStorage('x-csrf-token');
              if (csrfToken) {
                request.set('X-Csrf-Token', csrfToken);
              }
            }

            if (params) request.query(params);

            if (data) {
              if (createOrUpdateMethods.includes(method)) {
                Object.keys(data).forEach(key => {
                  if (data[key])
                    data[key] = utils.trimStrings(data[key], passwordFields);
                });
              }
              request.send(data);
            }

            if (field) {
              Object.keys(field).forEach(key => {
                if (field[key]) {
                  const trimmedValue = utils.trimStrings(
                    field[key],
                    passwordFields
                  );
                  request.field(key, trimmedValue);
                }
              });
            }

            if (attach) {
              Object.keys(attach).forEach(key => {
                if (typeof attach[key] === 'object')
                  request.attach(key, attach[key]);
                else request.field(key, attach[key]);
              });
            }
          }

          // If this is being called in test mode then resolve the promise with the data necessary
          // to confirm that the action creator is generating the expected request url and data

          // simulate can either be 'success' or 'error'. Used to simulate a response for testing
          if (simulate === 'response') {
            const response = {
              attach: null,
              data: null,
              field: null,
              method,
              url: path
            };
            if (data) response.data = data;
            if (field) response.field = field;
            if (attach) response.attach = attach;
            resolve(response);
          } else if (simulate === 'error') {
            reject(new Error('test error'));
          }

          request.end((err, res) => {
            const { text } = res || {};
            if (res?.headers['x-csrf-token']) {
              utils.setLocalStorage(
                'x-csrf-token',
                res.headers['x-csrf-token']
              );
            }

            // Start the timer to hit the session timeout endpoint
            const sessionModal = document?.getElementById('session-modal');
            if (sessionModal?.classList.contains('open'))
              sessionModal.classList.remove('open');

            if (isAuthenticatedPath(window.location.pathname)) {
              sessionTimerWorker.postMessage({
                host: window.location.origin
              });
            }

            // Allow for non-JSON responses
            let response;

            if (isBlob) {
              response = res?.body;
            } else {
              try {
                response = JSON.parse(text);
              } catch {
                response = text;
              }
            }

            return err
              ? reject(text ? { body: response, status_code: err.status } : err)
              : resolve(response || {});
          });
        });
    });
  }
}

export default ApiClient;
